.presentation-in-progress {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .btn {
        font-size: 24px;
    }

    h3 {
        font-size: 24px;
    }
}
