.app {
  width: 100%;
  height: calc(100% - 80px);
  margin-top: 80px;
}

// .btn {
//   width: 90%;
//   height: 20vh;
//   font-size: 28px !important;
// }
