.splash-screen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .logo {
        width: 60%;
        position: absolute;
        top: 20%;
    }

    .btn-start {
        font-size: 24px;
    }

    .welcome-message {
        font-size: 32px;
    }

    h3 {
        font-size: 24px;
    }
}
